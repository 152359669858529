import { NormalProgramme } from 'egenie-utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'dateRange',
        field: 'createDateStr',
        label: '日期',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
        isParamList: true,
      },
      {
        type: 'select',
        field: 'vendorIds',
        label: '供应商',
        mode: 'multiple',
        isParamList: true,
      },
      {
        type: 'select',
        field: 'verificationStatus',
        label: '核销状态',
        data: [
          {
            value: '0',
            label: '未核销',
          },
          {
            value: '1',
            label: '已核销',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
