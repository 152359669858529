import { Card, Layout } from 'antd';
import type { NormalProgramme, MainSubStructureModel } from 'egenie-utils';
import { ExportModal, MainSubStructure, NormalProgrammeComponent, ExportStore } from 'egenie-utils';
import React from 'react';
import { getAllVendor, getOwner } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getOwner()
      .then((ownerIds) => this.normalProgramme.filterItems.addDict({ ownerIds }));

    getAllVendor()
      .then((vendorIds) => this.normalProgramme.filterItems.addDict({ vendorIds }));
  }

  public mainSubStructureModel: MainSubStructureModel;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public normalProgramme: NormalProgramme;
}

const store = new Store();

export default function() {
  return (
    <>
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <ExportModal store={store.exportStore}/>
    </>
  );
}
