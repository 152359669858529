import { Input, message, Modal } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel, TimeStampFormatter } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    pageId: '60100',
    grid: {
      columns: [
        {
          key: 'createDateStr',
          name: '日期',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createDateStr}/>,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'diffNum',
          name: '差异数量',
          width: 80,
        },
        {
          key: 'affiliation',
          name: '所属权',
          width: 150,
        },
        {
          key: 'verify',
          name: '核销状态',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.verify ? '已核销' : '未核销'}
            </span>
          ),
        },
        {
          key: 'remark',
          name: '备注',
          width: 200,
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: MainItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <Input
                      onBlur={() => {
                        request({
                          url: '/api/cloud/wms/rest/bill/stDiff/updateRemark',
                          method: 'post',
                          data: {
                            wmsStDiffId: row.wmsStDiffId,
                            remark: context.mainSubStructureModel.gridModel.rows[rowIdx].remark,
                          },
                        })
                          .then(() => {
                            message.success({
                              key: '备注修改成功',
                              content: '备注修改成功',
                            });
                          });
                      }}
                      onChange={(event) => context.mainSubStructureModel.gridModel.rows[rowIdx].remark = event.target.value}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={context.mainSubStructureModel.gridModel.rows[rowIdx].remark}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsStDiffId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/inventoryVariance/index',
    },
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.mainSubStructureModel.gridModel.selectedIds).join(',');
          const fileName = '盘点差异';
          const exportType = 'cloud_wms_st_diff';
          const queryParam = context.normalProgramme.filterItems.params;
          const queryParamShow = context.normalProgramme.filterItems.translateParams.join(' ');
          queryParam.ownerOrVendorIds = (queryParam.ownerIds as string[] || []).concat(queryParam.vendorIds as string[] || []).join(',');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        text: '核销',
        permissionId: '4036',
        handleClick: () => {
          const selectRows: MainItem[] = context.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            message.warning({
              key: '请选择',
              content: '请选择',
            });
            return;
          }

          if (selectRows.some((item) => item.verify)) {
            message.warning({
              key: '存在已核销数据',
              content: '存在已核销数据',
            });
            return;
          }

          Modal.confirm({
            content: '确认核销?',
            onOk: () => request({
              url: '/api/cloud/wms/rest/bill/stDiff/verification',
              method: 'post',
              data: { wmsStDiffIds: selectRows.map((item) => item.wmsStDiffId) },
            })
              .then(() => {
                message.success('核销成功');
                context.mainSubStructureModel.gridModel.resetAll();
                context.mainSubStructureModel.onRefresh();
              }),
          });
        },
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/bill/stDiff/query',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            ownerOrVendorIds: (filterParams.ownerIds || []).concat(filterParams.vendorIds || []).join(','),
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}
